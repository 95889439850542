@use 'default.them';
@use 'tailwind-colors';
@use '../../../node_modules/animate.css/animate.min.css';
@use 'ngx-toastr/toastr';
@use '../../../node_modules/angular-calendar/css/angular-calendar.css';
@use 'card';
@use 'slide-toggle';
@use 'beauty-scroll';

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}
body {
  @apply text-txt;
  margin: 0;
  font-family: 'Nunito Sans';
}

// Material Dialog Styles
.normal-modal .mat-mdc-dialog-container {
  padding: 0 !important;
  border-radius: 8px !important;
  max-height: 90vh !important;
}

.full-screen-modal .mat-mdc-dialog-container {
  padding: 0 !important;
  border-radius: 0px !important;
  width: 100%;
  height: 100%;
}

.mat-mdc-sort-header-content {
  text-align: left !important;
}

.mg-menu-backdrop {
  @apply bg-txt/40;
}

.mg-disable-backdrop-click {
  .mg-menu-backdrop {
    @apply pointer-events-none;
  }
}

.mg-filter-menu {
  @apply mt-sm mb-lg max-h-[90vh] flex flex-col;

  .mat-mdc-menu-content {
    @apply bg-contrast py-0 flex flex-col overflow-auto;
  }
}

.mat-mdc-menu-panel {
  max-width: none !important;
  border-radius: 4px !important;
}

// Material Table Style
.mat-sort-header-content {
  @apply text-left #{!important};
}

* {
  -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
  print-color-adjust: exact !important; /*Firefox*/
}

// Loading Animation
.mg-loading-item {
  @apply bg-white/50 dark:bg-black/50;
  // animation: 1.5s shine linear infinite;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

// Input Autofill remove background
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
