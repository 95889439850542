// beautifull scroll
.beautyScroll {
  scrollbar-width: thin;
  scrollbar-color: #0078d4;
}

.beautyScroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.beautyScroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
}

.beautyScroll::-webkit-scrollbar-thumb {
  background-color: #0078d4;
}
