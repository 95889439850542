// Material Slide Toggle
.mat-mdc-slide-toggle {
  @apply pl-2xs;
  .mdc-switch__track {
    @apply w-[36px] h-xl rounded-full;
  }

  .mdc-switch__handle {
    @apply rounded-full w-lg h-lg #{!important};
  }

  .mdc-switch--unselected {
    .mdc-switch__handle {
      @apply ml-2xs #{!important};
    }
  }

  .mdc-switch__handle-track {
    width: calc(100% - 24px);
  }

  .mdc-switch {
    @apply w-[42px];

    &:active,
    &:hover,
    &:focus {
      .mdc-switch__track {
        &:before {
          background: none !important;
        }
      }
    }
  }

  .mdc-switch__shadow {
    @apply bg-white;
  }

  .mdc-switch__track {
    @apply bg-txt-disabled outline outline-ui-separator;
    outline-style: none;

    &:before {
      background: none;
    }
  }

  .mdc-switch--selected.mdc-switch--checked {
    .mdc-switch__track {
      @apply bg-primary outline outline-primary;
      outline-style: none;
    }
  }
}
