.mg-card {
  @apply p-xl rounded-base bg-ui-card print:border print:border-gray-200;

  &-clickable {
    @apply hover:bg-ui-bg #{!important};
  }
}

.mg-card-title {
  @apply text-txt font-semibold text-base leading-base capitalize;
}

.mg-card-subtitle {
  @apply mt-xs text-txt text-sm leading-sm;
}
