body {
  --color-error-light: 211, 47, 47;
  --color-warning-light: 245, 124, 0;
  --color-info-light: 3, 169, 244;
  --color-success-light: 76, 175, 80;
}

@media print {
  body {
    --color-white: 255, 255, 255;

    --color-black: 17, 32, 39;

    --color-primary: 0, 120, 212;

    --color-contrast: 255, 255, 255;

    --color-txt: 17, 32, 39;
    --color-txt-secondary: 107, 121, 128;
    --color-txt-disabled: 209, 216, 219;

    --color-ui-page: 235, 240, 244;
    --color-ui-card: 255, 255, 255;
    --color-ui-bg: 249, 250, 251;
    --color-ui-separator: 209, 216, 219;
    --color-ui-fields: 249, 250, 251;
    --color-ui-pills: 229, 233, 235;
    --color-ui-selected: 0, 120, 212;
    --color-ui-hover: 242, 248, 253;
    --color-ui-hover-1: 229, 241, 251;

    --color-error: 211, 47, 47;
    --color-warning: 245, 124, 0;
    --color-info: 3, 169, 244;
    --color-success: 76, 175, 80;

    --color-gray-50: 249, 250, 251;
    --color-gray-100: 238, 240, 242;
    --color-gray-200: 229, 233, 235;
    --color-gray-300: 209, 216, 219;
    --color-gray-400: 156, 169, 175;
    --color-gray-500: 107, 121, 128;
    --color-gray-600: 75, 91, 99;
    --color-gray-700: 55, 72, 81;
    --color-gray-800: 31, 47, 55;
    --color-gray-900: 17, 32, 39;
    --color-gray-1000: 9, 14, 17;
  }
}

@media not print {
  body:not(.dark) {
    --mg-background: linear-gradient(128deg, #ebf0f4 3.68%, #dee8ed 46.12%, #e2deed 89.41%);

    --color-white: 255, 255, 255;

    --color-black: 17, 32, 39;

    --color-primary: 0, 120, 212;

    --color-contrast: 255, 255, 255;

    --color-txt: 17, 32, 39;
    --color-txt-secondary: 107, 121, 128;
    --color-txt-disabled: 209, 216, 219;

    --color-ui-page: 235, 240, 244;
    --color-ui-card: 255, 255, 255;
    --color-ui-bg: 249, 250, 251;
    --color-ui-separator: 209, 216, 219;
    --color-ui-fields: 249, 250, 251;
    --color-ui-pills: 229, 233, 235;
    --color-ui-selected: 0, 120, 212;
    --color-ui-hover: 242, 248, 253;
    --color-ui-hover-1: 229, 241, 251;

    --color-error: 211, 47, 47;
    --color-warning: 245, 124, 0;
    --color-info: 3, 169, 244;
    --color-success: 76, 175, 80;

    --color-gray-50: 249, 250, 251;
    --color-gray-100: 238, 240, 242;
    --color-gray-200: 229, 233, 235;
    --color-gray-300: 209, 216, 219;
    --color-gray-400: 156, 169, 175;
    --color-gray-500: 107, 121, 128;
    --color-gray-600: 75, 91, 99;
    --color-gray-700: 55, 72, 81;
    --color-gray-800: 31, 47, 55;
    --color-gray-900: 17, 32, 39;
    --color-gray-1000: 9, 14, 17;
  }

  body.dark {
    --mg-background: linear-gradient(128deg, #0b0611 3.68%, #140d1c 95.41%);

    --color-white: 255, 255, 255;

    --color-black: 17, 32, 39;

    --color-primary: 10, 149, 255;

    --color-contrast: 0, 0, 0;

    --color-txt: 255, 255, 255;
    --color-txt-secondary: 75, 91, 99;
    --color-txt-disabled: 156, 169, 175;

    --color-ui-page: 9, 14, 17;
    --color-ui-card: 17, 32, 39;
    --color-ui-bg: 31, 47, 55;
    --color-ui-separator: 75, 91, 99;
    --color-ui-fields: 17, 32, 39;
    --color-ui-pills: 31, 47, 55;
    --color-ui-selected: 0, 120, 212;
    --color-ui-hover: 7, 35, 56;
    --color-ui-hover-1: 5, 56, 95;

    --color-error: 220, 91, 91;
    --color-warning: 245, 124, 0;
    --color-info: 3, 169, 244;
    --color-success: 76, 175, 80;

    --color-gray-50: 9, 14, 17;
    --color-gray-100: 17, 32, 39;
    --color-gray-200: 31, 47, 55;
    --color-gray-300: 55, 72, 81;
    --color-gray-400: 75, 91, 99;
    --color-gray-500: 107, 121, 128;
    --color-gray-600: 156, 169, 175;
    --color-gray-700: 209, 216, 219;
    --color-gray-800: 229, 233, 235;
    --color-gray-900: 243, 245, 246;
    --color-gray-1000: 249, 250, 251;
  }
}

body:not(.palette2):not(.palette3):not(.palette4):not(.palette5) {
  --color-graph-1: 150, 115, 211;
  --color-graph-1-gradiant: #443363;
  --color-graph-1-hsl: 262, 52%;
  --color-graph-1-hsl-start-point: 50;
  --color-graph-1-plus-hsl: 262, 52%;
  --color-graph-1-plus-hsl-start-point: 80;
  --color-graph-2: 235, 188, 0;
  --color-graph-2-gradiant: #8c720c;
  --color-graph-2-hsl: 48, 100%;
  --color-graph-2-hsl-start-point: 50;
  --color-graph-3: 224, 114, 102;
  --color-graph-4: 115, 211, 150;
  --color-graph-5: 209, 216, 219;
  --color-graph-6: 102, 14, 255;
}

body.palette2 {
  --color-graph-1: 92, 175, 226;
  --color-graph-1-gradiant: #17567a;
  --color-graph-1-hsl: 203, 70%;
  --color-graph-1-hsl-start-point: 60;
  --color-graph-1-plus-hsl: 203, 70%;
  --color-graph-1-plus-hsl-start-point: 85;
  --color-graph-2: 255, 111, 97;
  --color-graph-2-gradiant: #842921;
  --color-graph-2-hsl: 5, 100%;
  --color-graph-2-hsl-start-point: 60;
  --color-graph-3: 226, 92, 175;
  --color-graph-4: 173, 203, 1;
  --color-graph-5: 209, 216, 219;
  --color-graph-6: 0, 135, 217;
}

body.palette3 {
  --color-graph-1: 0, 190, 204;
  --color-graph-1-gradiant: #036b72;
  --color-graph-1-hsl: 184, 100%;
  --color-graph-1-hsl-start-point: 40;
  --color-graph-1-plus-hsl: 184, 100%;
  --color-graph-1-plus-hsl-start-point: 80;
  --color-graph-2: 255, 149, 20;
  --color-graph-2-gradiant: #a05c0e;
  --color-graph-2-hsl: 33, 100%;
  --color-graph-2-hsl-start-point: 50;
  --color-graph-3: 204, 0, 190;
  --color-graph-4: 146, 204, 0;
  --color-graph-5: 209, 216, 219;
  --color-graph-6: 0, 151, 172;
}

body.palette4 {
  --color-graph-1: 111, 166, 160;
  --color-graph-1-gradiant: #35635e;
  --color-graph-1-hsl: 173, 24%;
  --color-graph-1-hsl-start-point: 50;
  --color-graph-1-plus-hsl: 173, 24%;
  --color-graph-1-plus-hsl-start-point: 80;
  --color-graph-2: 219, 214, 92;
  --color-graph-2-gradiant: #7c7829;
  --color-graph-2-hsl: 58, 64%;
  --color-graph-2-hsl-start-point: 50;
  --color-graph-3: 160, 111, 166;
  --color-graph-4: 166, 160, 111;
  --color-graph-5: 209, 216, 219;
  --color-graph-6: 0, 136, 122;
}

body.palette5 {
  --color-graph-1: 159, 98, 85;
  --color-graph-1-gradiant: #5b2f26;
  --color-graph-1-hsl: 11, 30%;
  --color-graph-1-hsl-start-point: 50;
  --color-graph-1-plus-hsl: 11, 30%;
  --color-graph-1-plus-hsl-start-point: 80;
  --color-graph-2: 165, 205, 96;
  --color-graph-2-gradiant: #587030;
  --color-graph-2-hsl: 82, 52%;
  --color-graph-2-hsl-start-point: 50;
  --color-graph-3: 89, 156, 166;
  --color-graph-4: 98, 85, 159;
  --color-graph-5: 209, 216, 219;
  --color-graph-6: 196, 48, 17;
}
